// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usageCard {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.tabContent {
    padding: 20px;
}

.selectContainer {
    max-width: 400px;
}

.memory-spec {
    font-weight: bold;
    color: #2c3e50;
    background-color: #f8f9fa;
    padding: 2px 6px;
    border-radius: 4px;
}

.instance-count {
    color: #1a73e8;
}

.quota-item {
    margin-bottom: 4px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Usage/style.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".usageCard {\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n}\n\n.tabContent {\n    padding: 20px;\n}\n\n.selectContainer {\n    max-width: 400px;\n}\n\n.memory-spec {\n    font-weight: bold;\n    color: #2c3e50;\n    background-color: #f8f9fa;\n    padding: 2px 6px;\n    border-radius: 4px;\n}\n\n.instance-count {\n    color: #1a73e8;\n}\n\n.quota-item {\n    margin-bottom: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
