import { memo } from 'react';
import { Link } from 'react-router-dom';

const Index = ({
  httpCode = '',
}) => {
  return (
    <div id="error-page" className="text-center">
    <h1>Oops!</h1>
    <p>Sorry, an unexpected error has occurred. {httpCode}</p>
    <Link to="/" className="btn btn-link">
      Back to Home
    </Link>
  </div>
  );
};

export default memo(Index);
