// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.group-card .list-group-item {
  padding: 0.5rem 0;
  border: none;
}

.group-card small.text-muted {
  font-size: 0.8rem;
}

.env-card {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  box-shadow: none;
}

.env-card:hover {
  border-color: #0d6efd;
}

.env-card .card-body {
  padding: 0.75rem;
}

/* Add some spacing for the form controls */
.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

/* Ensure buttons and inputs align properly */
.gap-2 {
  gap: 0.5rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Group/style.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAC3C;;AAEA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA,2CAA2C;AAC3C;EACE,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA,6CAA6C;AAC7C;EACE,sBAAsB;AACxB","sourcesContent":[".group-card {\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);\n}\n\n.group-card .list-group-item {\n  padding: 0.5rem 0;\n  border: none;\n}\n\n.group-card small.text-muted {\n  font-size: 0.8rem;\n}\n\n.env-card {\n  background-color: #f8f9fa;\n  border: 1px solid #e9ecef;\n  box-shadow: none;\n}\n\n.env-card:hover {\n  border-color: #0d6efd;\n}\n\n.env-card .card-body {\n  padding: 0.75rem;\n}\n\n/* Add some spacing for the form controls */\n.form-control-sm {\n  padding: 0.25rem 0.5rem;\n  font-size: 0.875rem;\n}\n\n/* Ensure buttons and inputs align properly */\n.gap-2 {\n  gap: 0.5rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
