import { Container, Row, Col, Button } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { getGithuhLoginUrl } from '../../services';

function Login() {
  return (
    <Container className="mt-5" style={{ 
      background: 'linear-gradient(to right, #e0eafc, #cfdef3)', 
      borderRadius: '15px', 
      padding: '40px', 
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' 
    }}>
      <Row className="justify-content-md-center">
        <Col md="6" className="text-center">
          <h1 style={{ color: '#343a40', fontWeight: 'bold', marginBottom: '20px' }}>Welcome to ConnectDev</h1>
          <p style={{ color: '#6c757d', fontSize: '1.1em', marginBottom: '30px' }}>Support Node.js, Python, Java, Go backend serverless programs.</p>
          <Button variant="success" size="lg" href={getGithuhLoginUrl()} style={{ 
            padding: '12px 25px', 
            fontSize: '1.3em', 
            borderRadius: '25px', 
            transition: 'background-color 0.3s ease' 
          }} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#28a745'} onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}>
            <Icon icon="fa-brands:github" width="1em" height="1em" /> Login With Github
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
