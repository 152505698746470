import axios from 'axios';
import type * as Type from '../common/interface';
import { ENGINE_API_URL } from '../common/constants';


const LOG_URL = `${ENGINE_API_URL}/1.0/engine/logs`;

export async function getLogs(
    appId: string, 
    masterKey: string, 
    from?: string,
    stream?: string
): Promise<Type.Log[]> {
    const headers = {
        'APP-ID': appId,
        'SECRET-KEY': masterKey
    };

    const params: Record<string, string> = {};
    if (from) params.from = from;
    if (stream) params.stream = stream;

    const response = await axios.get(LOG_URL, { headers, params });
    return response.data;
}