import { FC, memo } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { UserInfo } from "../../../common/interface";

interface Props {
  userInfo: UserInfo;
  logOut: () => void;
}

const NavItems: FC<Props> = ({ userInfo, logOut }) => {
  return (
    <div className="d-flex align-items-center">
      <Dropdown align="end" className="me-2">
        <Dropdown.Toggle variant="success" className="custom-toggle">
          Console
        </Dropdown.Toggle>
        <Dropdown.Menu className="custom-menu">
          <Dropdown.Item
            href="/console/applications"
            className="custom-item"
            active={window.location.pathname === "/console/applications"}
          >
            Apps
          </Dropdown.Item>
          <Dropdown.Item
            href='/account'
            className="custom-item"
            active={window.location.pathname === "/account"}
          >
            Account
          </Dropdown.Item>
          <Dropdown.Item
            href='/usage'
            className="custom-item"
            active={window.location.pathname === "/usage"}
          >
            Usage
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Button 
        onClick={logOut} 
        variant="outline-secondary" 
        className="logout-btn"
      >
        Logout
      </Button>
    </div>
  );
};

export default memo(NavItems);
