// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpgradePlan_planContainer__\\+KRCT {
  padding: 20px 0;
}

.UpgradePlan_section__Dg8qx {
  margin-bottom: 24px;
}

.UpgradePlan_section__Dg8qx h3 {
  margin-bottom: 16px;
  font-weight: 500;
}

.UpgradePlan_planGrid__2iKdP {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.UpgradePlan_planCard__2Dx3v {
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s;
}

.UpgradePlan_planCard__2Dx3v:hover {
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.UpgradePlan_planCard__2Dx3v.UpgradePlan_selected__T-2Te {
  border-color: #0d6efd;
  background-color: rgba(13, 110, 253, 0.1);
}

.UpgradePlan_specs__f3ech {
  margin: 12px 0;
  font-size: 20px;
  font-weight: 500;
}

.UpgradePlan_specs__f3ech span {
  margin-right: 12px;
}

.UpgradePlan_description__rV6oG {
  color: #6c757d;
  font-size: 14px;
  margin-top: 12px;
}

.UpgradePlan_notice__ATJ0t {
  margin-top: 20px;
  padding: 12px 16px;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  color: #856404;
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.UpgradePlan_warningIcon__6WwST {
  font-size: 18px;
  color: #f0ad4e;
  flex-shrink: 0;
  margin-top: 2px;
}

.UpgradePlan_disabled__3nl2H {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
} `, "",{"version":3,"sources":["webpack://./src/components/UpgradePlan/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,2DAA2D;EAC3D,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,aAAa;EACb,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;EACrB,kDAAkD;AACpD;;AAEA;EACE,qBAAqB;EACrB,yCAAyC;AAC3C;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,cAAc;EACd,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".planContainer {\n  padding: 20px 0;\n}\n\n.section {\n  margin-bottom: 24px;\n}\n\n.section h3 {\n  margin-bottom: 16px;\n  font-weight: 500;\n}\n\n.planGrid {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n  gap: 16px;\n}\n\n.planCard {\n  border: 1px solid #dee2e6;\n  border-radius: 0.375rem;\n  padding: 16px;\n  cursor: pointer;\n  transition: all 0.2s;\n}\n\n.planCard:hover {\n  border-color: #0d6efd;\n  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);\n}\n\n.planCard.selected {\n  border-color: #0d6efd;\n  background-color: rgba(13, 110, 253, 0.1);\n}\n\n.specs {\n  margin: 12px 0;\n  font-size: 20px;\n  font-weight: 500;\n}\n\n.specs span {\n  margin-right: 12px;\n}\n\n.description {\n  color: #6c757d;\n  font-size: 14px;\n  margin-top: 12px;\n}\n\n.notice {\n  margin-top: 20px;\n  padding: 12px 16px;\n  background-color: #fff3cd;\n  border: 1px solid #ffeeba;\n  border-radius: 4px;\n  color: #856404;\n  display: flex;\n  align-items: flex-start;\n  gap: 12px;\n}\n\n.warningIcon {\n  font-size: 18px;\n  color: #f0ad4e;\n  flex-shrink: 0;\n  margin-top: 2px;\n}\n\n.disabled {\n  opacity: 0.6;\n  cursor: not-allowed;\n  pointer-events: none;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"planContainer": `UpgradePlan_planContainer__+KRCT`,
	"section": `UpgradePlan_section__Dg8qx`,
	"planGrid": `UpgradePlan_planGrid__2iKdP`,
	"planCard": `UpgradePlan_planCard__2Dx3v`,
	"selected": `UpgradePlan_selected__T-2Te`,
	"specs": `UpgradePlan_specs__f3ech`,
	"description": `UpgradePlan_description__rV6oG`,
	"notice": `UpgradePlan_notice__ATJ0t`,
	"warningIcon": `UpgradePlan_warningIcon__6WwST`,
	"disabled": `UpgradePlan_disabled__3nl2H`
};
export default ___CSS_LOADER_EXPORT___;
