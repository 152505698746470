// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricing-col {
  max-width: 400px;
}

.hover-effect {
  transition: transform 0.2s ease-in-out;
}

.hover-effect:hover {
  transform: translateY(-5px);
}

.pricing-amount {
  text-align: center;
  margin: 1.5rem 0;
}

.pricing-amount .display-4 {
  font-weight: bold;
  color: #0d6efd;
}

.pricing-intro {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.25rem;
  line-height: 1.6;
}

.divider {
  height: 3px;
  background: linear-gradient(90deg, transparent, #0d6efd, transparent);
  width: 60%;
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Pricing/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,qEAAqE;EACrE,UAAU;EACV,cAAc;AAChB","sourcesContent":[".pricing-col {\n  max-width: 400px;\n}\n\n.hover-effect {\n  transition: transform 0.2s ease-in-out;\n}\n\n.hover-effect:hover {\n  transform: translateY(-5px);\n}\n\n.pricing-amount {\n  text-align: center;\n  margin: 1.5rem 0;\n}\n\n.pricing-amount .display-4 {\n  font-weight: bold;\n  color: #0d6efd;\n}\n\n.pricing-intro {\n  max-width: 800px;\n  margin: 0 auto;\n  font-size: 1.25rem;\n  line-height: 1.6;\n}\n\n.divider {\n  height: 3px;\n  background: linear-gradient(90deg, transparent, #0d6efd, transparent);\n  width: 60%;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
