// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application-list {
  margin-top: 3rem;
}

.application-card {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.application-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.application-card .card-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.application-card .card-text {
  font-size: 0.95rem;
  color: #6c757d;
}

.application-card a {
  color: #2c3e50;
}

.application-card a:hover {
  color: #0056b3;
}

.btn_center {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/ApplicationList/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mEAAmE;EACnE,oCAAoC;AACtC;;AAEA;EACE,2BAA2B;EAC3B,qDAAqD;AACvD;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".application-list {\n  margin-top: 3rem;\n}\n\n.application-card {\n  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n}\n\n.application-card:hover {\n  transform: translateY(-5px);\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;\n}\n\n.application-card .card-title {\n  font-size: 1.25rem;\n  margin-bottom: 1rem;\n}\n\n.application-card .card-text {\n  font-size: 0.95rem;\n  color: #6c757d;\n}\n\n.application-card a {\n  color: #2c3e50;\n}\n\n.application-card a:hover {\n  color: #0056b3;\n}\n\n.btn_center {\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
