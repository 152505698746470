import React,{useEffect, useState}from 'react';
import './index.css'
import type * as Type from '../../common/interface';
import {getApplication } from '../../services/application';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Icon } from '@iconify/react';
import { loggedUserInfoStore } from '../../stores';
import { useNavigate } from 'react-router-dom';
import {  Row, Col, Tabs, Tab } from 'react-bootstrap';
import {
    DLogs,
    Deploys
} from '../../components';
import GuidedTour from '../../components/GuidedTour';
import GroupComponent from '@/components/Group';
import { getGroups } from '../../services/group';


const Index:React.FC = () => {
    const currentUrl = window.location.href;
    const appId = currentUrl.split('/').pop() || '';
    const [appInfo, setAppInfo] = useState<Type.ApplicationInfo | null>(null);
    const [copied, setCopied] = useState(false);
    const [activeKey, setActiveKey] = useState('deploys'); 
    const [hasDeployData, setHasDeployData] = useState(false);
    const [groupData, setGroupData] = useState<Type.Group | null>(null);



    useEffect(() => {
       getApplication(appId).then((res) => {
            setAppInfo(res);
        });
        
    }, [appId]);
    useEffect(() => {
        if (appInfo?.appId && appInfo?.masterKey) {
            getGroups(appInfo.appId, appInfo.masterKey).then((res) => {
                setGroupData(res[0]);
            });
        }
    }, [appInfo]);


    const handleCopy = () => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    };


    const { user } = loggedUserInfoStore();
    const navigate = useNavigate();

     if (!user.clientId) {
        navigate('/');
        return null; 
    }

    const setupSteps = [
      {
        number: 1,
        title: "Install the client",
        content: (
          <>
            <p>Run this in order to install the client library:</p>
          </>
        ),
        commands: [
          "pip install connectdev",
          "connectdev --help"
        ]
      },
      {
        number: 2,
        title: "Link project",
        content: (
          <>
            <p>Run this in order to link your project to the application:</p>
          </>
        ),
        commands: [
          "connectdev link"
        ]
      },
      { 
        number: 3,
        title: "Run and Debug Locally",
        content: (
          <>
            <p>Run the following command under the root directory of your project to run and debug the project locally:</p>
          </>
        ),
        commands: [
          "connectdev start"
        ]
      },
      { 
        number: 4,
        title: "Deploy",
        content: (
          <>
            <p>Once you’ve finished developing and testing your project, you can deploy it to ConnectDev</p>
          </>
        ),
        commands: [
          "connectdev deploy --m 'message'"
        ]

      }
    ];

    const handleDeployDataStatus = (hasData: boolean) => {
        setHasDeployData(hasData);
    };

    return (
      
      <div className='application-detail'>
        {!hasDeployData && (
          <div className="setup-guide">
            <h4>Getting Started</h4>
            <GuidedTour steps={setupSteps} />
          </div>
        )}
        
        <div className="info-section card p-3 mb-3">
          <h4 className="border-bottom pb-2 mb-2">Basic Information</h4>
          <Row className="g-2">
            <Col md={4}>
              <h5 className="text-muted small mb-1">Name</h5>
              <p className="mb-0 fw-medium fs-6">{appInfo?.name}</p>
            </Col>
            <Col md={4}>
              <h5 className="text-muted small mb-1">Domain</h5>
              <p className="mb-0 fw-medium fs-6">{appInfo?.domain}</p>
            </Col>
            <Col md={12} className="mt-2">
              <h5 className="text-muted small mb-1">Description</h5>
              <p className="mb-0 fw-medium fs-6">{appInfo?.description}</p>
            </Col>
          </Row>
        </div>

        {appInfo && (
          <div className="info-section card p-3">
            <h4 className="border-bottom pb-2 mb-2">Credentials</h4>
            <div className="credentials-grid row g-3">
              <div className="col-12">
                <h5 className="text-muted small mb-1">AppID</h5>
                <CopyToClipboard text={appInfo.appId} onCopy={handleCopy}>
                  <div className='copy-container py-1 px-2 bg-light rounded'>
                    <p className="mb-0 me-2 fw-medium fs-6">{appInfo.appId}</p>
                    <button className="copy-button btn btn-light p-1">
                      <Icon icon="icon-park-twotone:copy" width="16" height="16" />
                    </button>
                  </div>
                </CopyToClipboard>
                <span className="text-muted small mt-1 d-inline">Unique identifier</span>
              </div>

              <div className="col-12">
                <h5 className="text-muted small mb-1">Secret Key</h5>
                <CopyToClipboard text={appInfo.masterKey} onCopy={handleCopy}>
                  <div className='copy-container py-1 px-2 bg-light rounded'>
                    <p className="mb-0 me-2 fw-medium fs-6">{appInfo.masterKey}</p>
                    <button className="copy-button btn btn-light p-1">
                      <Icon icon="icon-park-twotone:copy" width="16" height="16" />
                    </button>
                  </div>
                </CopyToClipboard>
                <span className="text-muted small mt-1 d-inline">Secret access key for trusted environments</span>
              </div>
            </div>
          </div>
        )}

        {copied && (
          <div className="copied-tooltip">
            Copied to clipboard!
          </div>
        )}

        {groupData && <GroupComponent group={groupData} appId={appInfo?.appId ?? ''} masterKey={appInfo?.masterKey ?? ''} />}

        <Tabs 
          defaultActiveKey="deploys" 
          id="deploy-management-tabs" 
          activeKey={activeKey} 
          onSelect={(k) => setActiveKey(k || 'deploys')}
          className="custom-tabs"
        >
          <Tab eventKey="deploys" title="Deploys">
            <Deploys 
              appId={appInfo?.appId} 
              masterKey={appInfo?.masterKey} 
              activeTab={activeKey}
              onDataStatusChange={handleDeployDataStatus}
            />
          </Tab>
          <Tab eventKey="logs" title="Logs">
            <DLogs appId={appInfo?.appId} masterKey={appInfo?.masterKey} activeTab={activeKey}/>
          </Tab>
        </Tabs>

        
      </div>
    )
}

export default React.memo(Index);