import { useEffect, useState } from 'react';
import { getDeployments } from '../../services';
import type * as Type from '../../common/interface';
import './index.css';

const getStatusVariant = (status: string) => {
    switch (status.toLowerCase()) {
        case 'success':
            return 'success';
        case 'failed':
            return 'failed';
        default:
            return 'pending';
    }
};

const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString();
};

interface DeploysProps {
    appId?: string;
    masterKey?: string;
    activeTab: string;
    onDataStatusChange?: (hasData: boolean) => void;
}

const Deploys: React.FC<DeploysProps> = ({ appId, masterKey, activeTab, onDataStatusChange }) => {
    const [deploys, setDeploys] = useState<Type.Deployment[]>([]);
    const [autoRefresh, setAutoRefresh] = useState(false);

    const fetchDeploys = async () => {
        if (!appId || !masterKey) {
            console.error('Missing appId or masterKey for fetching deploys');
            return;
        }
        try {
            const logs = await getDeployments(appId, masterKey);
            setDeploys(logs);
            onDataStatusChange?.(logs.length > 0);
        } catch (error) {
            console.error('Failed to fetch deploys:', error);
            onDataStatusChange?.(false);
        }
    };

    useEffect(() => {
        if (activeTab === 'deploys') {
            fetchDeploys();
        }
    }, [appId, masterKey, activeTab]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        
        if (autoRefresh && activeTab === 'deploys') {
            intervalId = setInterval(fetchDeploys, 5000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [autoRefresh, activeTab]);

    return (
        <div className='container'>
            <div className="auto-update-control">
                <label>
                    <input
                        type="checkbox"
                        checked={autoRefresh}
                        onChange={(e) => setAutoRefresh(e.target.checked)}
                    />
                    Auto Refresh (5s)
                </label>
            </div>
            
            {deploys.map(deploy => (
                <div className='entry' key={deploy.startedAt}>
                    <div className="status-header">
                        <span className={`status-badge ${getStatusVariant(deploy.status)}`}>
                            {deploy.status}
                        </span>
                        {deploy.error && (
                            <span className="error-message">
                                {deploy.failedPhrase}: {deploy.error}
                            </span>
                        )}
                    </div>

                    <div className="deployment-times">
                        <div className="time-item">
                            <span className="time-label">Started At</span>
                            <span className="time-value">{formatDate(deploy.startedAt)}</span>
                        </div>
                        <div className="time-item">
                            <span className="time-label">Deployed At</span>
                            <span className="time-value">{formatDate(deploy.deployedAt)}</span>
                        </div>
                        <div className="time-item">
                            <span className="time-label">Finished At</span>
                            <span className="time-value">{formatDate(deploy.finishedAt)}</span>
                        </div>
                    </div>

                    {deploy.version && (
                        <div className="version-info">
                            <div className="version-header">Version Information</div>
                            <div className="version-details">
                                <div className="version-item">
                                    <span className="version-label">Runtime</span>
                                    <span className="version-value">{deploy.version.runtime}</span>
                                </div>
                                <div className="version-item">
                                    <span className="version-label">Version</span>
                                    <span className="version-value">{deploy.version.version}</span>
                                </div>
                                <div className="version-item">
                                    <span className="version-label">Image Tag</span>
                                    <span className="version-value">{deploy.version.imageTag}</span>
                                </div>
                                {deploy.version.comment && (
                                    <div className="version-item">
                                        <span className="version-label">Comment</span>
                                        <span className="version-value">{deploy.version.comment}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Deploys;