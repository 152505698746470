import type { NavigateFunction } from 'react-router-dom';
import { LOGGED_TOKEN_STORAGE_KEY } from '../common/constants';
import Storage from './storage';
import {getLoggedUserInfo} from '../services/user';
import { loggedUserInfoStore } from '../stores';
import{REDIRECT_PATH_STORAGE_KEY, HOME_URL, LOGIN_URL} from '../common/constants';



type NavigateHandler = 'href' | 'replace' | NavigateFunction;
export interface NavigateConfig {
  handler?: NavigateHandler;
  options?: any;
}


const navigate = (to: string | number, config: NavigateConfig = {}) => {
    let { handler = 'href' } = config;
    if (to && typeof to === 'string') {
      if (equalToCurrentHref(to)) {
        return;
      }
      /**
       * 1. Blocking redirection of two login pages
       * 2. Auto storage login redirect
       * Note: The or judgement cannot be missing here, both jumps will be used
       */
      if (to === LOGIN_URL) {
        storageLoginRedirect();
      }
  
      if (!isRoutableLink(to) && handler !== 'href' && handler !== 'replace') {
        handler = 'href';
      }
      if (handler === 'href' && config.options?.replace) {
        handler = 'replace';
      }
      if (handler === 'href') {
        window.location.href = to;
      } else if (handler === 'replace') {
        window.location.replace(to);
      } else if (typeof handler === 'function') {
        handler(to, config.options);
      }
    }
    if (typeof to === 'number' && typeof handler === 'function') {
      handler(to);
    }
  };


  export const navigateToLogin = (config?: NavigateConfig) => {
    navigate(LOGIN_URL, config);
  };

  const storageLoginRedirect = () => {
    const { pathname } = window.location;
    if (pathname !== LOGIN_URL) {
      const loc = window.location;
      const redirectUrl = loc.href.replace(loc.origin, '');
      Storage.set(REDIRECT_PATH_STORAGE_KEY, redirectUrl);
    }
  };


  const equalToCurrentHref = (target: string, base?: string) => {
    base ||= window.location.origin;
    const targetUrl = new URL(target, base);
    return targetUrl.toString() === window.location.href;
  };

  /**
 * Determining if an url is a full link
 */
const isFullLink = (url = '') => {
    let ret = false;
    if (/^(http:|https:|\/\/)/i.test(url)) {
      ret = true;
    }
    return ret;
  };
  
  /**
   * Determining if a link is routable
   */
  const isRoutableLink = (url = '') => {
    let ret = true;
    if (isFullLink(url)) {
      ret = false;
    }
  
    return ret;
  };
  


export const handleLoginRedirect = (handler?: NavigateConfig['handler']) => {
    const redirectUrl = Storage.get(REDIRECT_PATH_STORAGE_KEY) || HOME_URL;
    Storage.remove(REDIRECT_PATH_STORAGE_KEY);
    navigate(redirectUrl, {
      handler,
      options: { replace: true },
    });
  };

/**
 * Unified processing of login logic after getting `access_token`
 */
export const handleLoginWithToken = (
    token: string | null,
    handler?: NavigateConfig['handler'],
  ) => {
    if (token) {
      Storage.set(LOGGED_TOKEN_STORAGE_KEY, token);
      setTimeout(() => {
        getLoggedUserInfo().then((res) => {
          loggedUserInfoStore.getState().update(res);
          handleLoginRedirect(handler);
        });
      });
    } else {
      navigate(HOME_URL, {
        handler,
        options: {
          replace: true,
        },
      });
    }
  };
  


  let appInitialized = false;
  let isLoggingOut = false;

  export const setLoggingOut = (status: boolean) => {
    isLoggingOut = status;
  };

  export const setupApp = async () => {
    if (appInitialized) {
      return;
    }

    // 如果没有 token，直接返回，不执行初始化逻辑
    const token = Storage.get(LOGGED_TOKEN_STORAGE_KEY);
    if (!token) {
      return;
    }
    
    await Promise.allSettled([pullLoggedUser(true)]);
    appInitialized = true;
  };



  let pluTimestamp = 0;
  export const pullLoggedUser = async (isInitPull = false) => {
    if (isLoggingOut) {
      return;
    }
    
    if (!isInitPull && Date.now() - pluTimestamp < 1000 * 10) {
      return;
    }
    pluTimestamp = Date.now();
    const loggedUserInfo = await getLoggedUserInfo({
      passingError: true,
    }).catch((ex) => {
      pluTimestamp = 0;
      loggedUserInfoStore.getState().clear(false);
      console.error(ex);
    });
    if (loggedUserInfo) {
      loggedUserInfoStore.getState().update(loggedUserInfo);
    }
  };

  export const handleLogout = (handler?: NavigateConfig['handler']) => {
    setLoggingOut(true);
    
    // Clear user related data
    Storage.remove(LOGGED_TOKEN_STORAGE_KEY);
    loggedUserInfoStore.getState().clear(false);
    
    // Navigate to home
    navigate("/", {
      handler: handler || 'replace',
      options: { replace: true },
    });
    
    // Reset the logging out flag after navigation
    setTimeout(() => {
      setLoggingOut(false);
    }, 100);
  };

  export const requireAuth = () => {
    const token = Storage.get(LOGGED_TOKEN_STORAGE_KEY);
    if (!token) {
      storageLoginRedirect();
      throw new Response("Unauthorized", { 
        status: 401,
        statusText: "Unauthorized" 
      });
    }
    return null;
  };
