import React,{useState, useEffect} from 'react';
import './index.css'
import { Container,Col, Row } from 'react-bootstrap';
import { loggedUserInfoStore } from '../../stores';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal,Form,Button } from 'react-bootstrap';
import { createPersonalToken } from '../../services/account';
import {ClientAuthTokens} from '../../common/interface';
import {listTokens, } from '../../services/account';


const EMPTY_VALUE = {
    value: '',
    errorMsg: '',
    isInvalid: false
}

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

const Index:React.FC = () => {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        name: EMPTY_VALUE,
      });
    
    const [tokenCreated, setTokenCreated] = useState(false);

    const [tokens, setTokens] = useState<ClientAuthTokens[]>([]);


    function onRefresh() {
            listTokens().then((res) => {
                setTokens(res || []);
            });
    }

    useEffect(() => {
        if (tokenCreated) {
            setTokenCreated(false);
        }
        onRefresh()
    }, [tokenCreated]);

    const { user } = loggedUserInfoStore();
    const navigate = useNavigate();
    

    if (!user.clientId) {
        navigate('/');
        return null; 
    }


     const handleClose = () => {
        setFormData({
            name: EMPTY_VALUE,
        });
        setShow(false);
    } 

    const handleSubmit = () => {
        if (!checkValidated()) {
            return;
        }

        setIsLoading(true);

        createPersonalToken({
            name: formData.name.value,
        }).then((res) => {
            setFormData({
                name: EMPTY_VALUE,
            });
            setShow(false);
            toast.success('Personal Token created successfully', {
                autoClose: 1000,
            }); 
            setTokenCreated(true);
        }).catch((err) => {
            toast.error('Network Error, Please try again later');
        }).finally(() => {
            setIsLoading(false);
        });
    }


    const checkValidated = (): boolean => {
        let bol = true;
        const { name } = formData;
        if (!name.value) {
            bol = false;
            formData.name = {
                value: '',
                isInvalid: true,
                errorMsg: 'Token Name cannot be empty',
            };
        } else if (name.value.length > 30) {
            bol = false;
            formData.name = {
                value: name.value,
                isInvalid: true,
                errorMsg: 'Token Name cannot be more than 30 characters',
            };
        }

        if (tokens.length >= 20 ) {
            bol = false;
            toast.error('You have reached the maximum number of tokens allowed');
        }
        
        setFormData({
            ...formData,
        });
        return bol;
    };

   

     const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: {
              ...prevData[name],
              value: value,
              isInvalid: false,
              errorMsg: '',
            }
        }));
      };


    return (
        <Container className="account-container">
            <Row className="info-section">
                <Col>
                    <h3>Basic Information</h3>
                    <hr className="basic-divider" />
                    <h5>Client Id</h5>
                    <code className="client-id">{user?.clientId}</code>
                </Col>
            </Row>
           
            <Row className="token-section">
                <Col xs={12} className="token-header">
                    <div>
                        <h3>Personal Token</h3>
                        <p className="text-muted">
                            <i>Generate personal token to use the ConnectDev CLI.</i>
                        </p>
                    </div>
                    <Button 
                        variant="outline-info"
                        className="generate-btn"
                        onClick={() => setShow(true)}>
                        Generate Token
                    </Button>
                </Col>

                <Col xs={12}>
                    <div className="token-table-container">
                        <Table hover className="token-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Token</th>
                                    <th>Created Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tokens.map((item) => (
                                    <tr key={item.Token}>
                                        <td>
                                            <div className="token-name">
                                                <span className="token-icon">🔑</span>
                                                {item.Name}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="token-value">
                                                <code>{item.Token}</code>
                                            </div>
                                        </td>
                                        <td>{formatDate(item.Created)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>

            <ToastContainer />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Generate Token</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form noValidate>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            autoFocus 
                            name="name" 
                            value={formData.name.value}  
                            isInvalid={formData.name.isInvalid}
                            onChange={handleInputChange}/>
                        <Form.Control.Feedback type="invalid">
                            {formData.name.errorMsg}
                        </Form.Control.Feedback>
                    </Form.Group>

                </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}  disabled={isLoading}>
                        {isLoading ? 'Creating...' : 'Create'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}


export default React.memo(Index);
