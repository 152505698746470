import axios from 'axios';
import type * as Type from '../common/interface';
import { ENGINE_API_URL } from '../common/constants';

const GROUP_URL = `${ENGINE_API_URL}/1.0/engine/groups`;

export async function getGroups(appId:string, masterKey:string): Promise<Type.Group[]>{
    const headers = {
        'APP-ID': appId,
        'SECRET-KEY': masterKey
    };
  
    const params: Record<string, string> = {};
    params.all = 'true';
    const response = await axios.get(GROUP_URL, { headers, params })
    return response.data
}


export async function autoScaleInstances(appId:string, masterKey:string, autoScale:string, scaleMax:number, scaleMin:number) : Promise<Type.Group>  {
    const headers = {
        'APP-ID': appId,
        'SECRET-KEY': masterKey
    };

    const response = await axios.patch(`${GROUP_URL}/web`, { 
        autoScale: autoScale,
        autoScaleMax: scaleMax,
        autoScaleMin: scaleMin
     }, { headers });
    return response.data;
}



export async function scaleInstances(appId:string, masterKey:string, instances:number)   {
    const headers = {
        'APP-ID': appId,
        'SECRET-KEY': masterKey
    };

    await axios.put(`${GROUP_URL}/web/production/instances`, { 
        instances: instances,
     }, { headers });
}



export async function restartInstances(appId:string, masterKey:string, config:any, env:string)   {
    const headers = {
        'APP-ID': appId,
        'SECRET-KEY': masterKey
    };
    try {
        await axios.put(`${GROUP_URL}/web/envs/${env}/restart`, config, { headers });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response?.data || error;
        }
        throw error;
    }
}
