import config from '../config';
import axios, { AxiosResponse } from 'axios';
import type { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { LOGGED_TOKEN_STORAGE_KEY } from '../common/constants';
import Storage from './storage';
import { navigateToLogin } from './guard';
import { loggedUserInfoStore } from '../stores';


const baseConfig = {
  timeout: 10000,
  withCredentials: true,
  baseURL: config.baseUrl,
};

interface ApiConfig extends AxiosRequestConfig {
  // Configure whether to allow takeover of 404 errors
  allow404?: boolean;
  ignoreError?: '403' | '50X';
  // Configure whether to pass errors directly
  passingError?: boolean;
}

class Request {
  instance: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.instance = axios.create(config);

    this.instance.interceptors.request.use(
      (config) => {
        const token = Storage.get(LOGGED_TOKEN_STORAGE_KEY) || '';
        config.headers.Authorization = token
        return config;
      },
      (err: AxiosError) => {
        console.error('request interceptors error:', err);
      },
    );

    this.instance.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      const { data } = response;
      return data;
    }, function (error) {
        const { status } = error.response || {};
        // 401: Re-login required
        if (status === 401) {
          // clear userinfo
          loggedUserInfoStore.getState().clear();
          navigateToLogin();
          return Promise.reject(false);
        }
      return Promise.reject(error);
    });

  }

  public request(config: AxiosRequestConfig): Promise<AxiosResponse> {
    return this.instance.request(config);
  }

  public get<T = any>(url: string, config?: ApiConfig): Promise<T> {
    return this.instance.get(url, config);
  }

  public post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.instance.post(url, data, config);
  }

  public put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.instance.put(url, data, config);
  }

  public delete<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.instance.delete(url, {
      data,
      ...config,
    });
  }
}

const request = new Request(baseConfig);
export default request;
