import axios from 'axios';
import type * as Type from '../common/interface';
import { ENGINE_API_URL } from '../common/constants';

const DEPLOYS_URL = `${ENGINE_API_URL}/1.0/engine/groups/web/deployments`;

export async function getDeployments(appId:string, masterKey:string): Promise<Type.Deployment[]>{
    const headers = {
        'APP-ID': appId,
        'SECRET-KEY': masterKey
    };

    const response = await axios.get(DEPLOYS_URL, { headers })
    return response.data
}