import axios from 'axios';
import type * as Type from '../common/interface';
import { ENGINE_API_URL } from '../common/constants';


const URL = `${ENGINE_API_URL}/1.0/engine/groups/web/instanceQuota`;

const headers = {
    'accept-language': 'en'
};

export async function getQuotaConfig(
    appId: string, 
    masterKey: string, 
): Promise<Type.QuotaConfig> {
    const requestHeaders = {
        ...headers,
        'APP-ID': appId,
        'SECRET-KEY': masterKey
    };   

    const response = await axios.get(URL, { headers: requestHeaders });
    return response.data;
}

export async function putProdQuota(
    appId: string, 
    masterKey: string,
    instanceQuota: string,
): Promise<Type.Group> {
    try {
        const response = await axios.put(URL, 
            { instanceQuota }, 
            { headers: { ...headers, 'APP-ID': appId, 'SECRET-KEY': masterKey } }
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response?.data || error;
        }
        throw error;
    }
}

export async function usage( 
    appId: string, 
    masterKey: string)
    : Promise<Type.DailyQuota> {
    try {
        const response = await axios.get(`${ENGINE_API_URL}/1.0/engine/stats/quotaUsage`, 
            { headers: { ...headers, 'APP-ID': appId, 'SECRET-KEY': masterKey } }
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response?.data || error;
        }
        throw error;
    }
}