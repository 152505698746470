import qs from 'qs';
import useSWR from 'swr';
import type * as Type from '../common/interface';
import request from '../utils/request';


export const createApplication = (params) => {
  return request.post('api/application', params);
};

export const getApplication = (appId: string) => {
    return request.get<Type.ApplicationInfo>(`api/application/${appId}`);
}
export const useApplicationSearch = (params) => {
    const apiUrl = 'api/applications';
    const { data, error, mutate } = useSWR<Type.ListResult, Error>(
      [apiUrl],
      request.instance.get,
    );
    console.log(error)
    console.log(apiUrl)
    return {
      data,
      isLoading: !data && !error,
      error,
      mutate,
    };
  };
  


export const getApplications = (params) => {
    const apiUrl = `api/applications?${qs.stringify(params)}`;
    return request.get<Type.ListResult>(apiUrl , {});
};

export const deleteApplication = (appId: string) => {
  return request.delete(`api/application/${appId}`);
};


