import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import './Features.css'; // 引入自定义样式

const Features = () => {
  return (
    <Container>
      <h1 className="text-center" style={{ paddingBottom: "30px", color: "#4A90E2" }}>Features</h1>
      <Row className="justify-content-center">
        <Col xs={12} md={6}>
          <Card className="mb-4 feature-card">
            <Card.Body>
              <div className="d-flex align-items-center mb-3">
                <Icon icon="mdi:server-network-off" width="3em" height="3em" />
                <h4 className="mb-0">Serverless backend</h4>
              </div>
              <p>Unleash the power of seamless backend hosting for your applications.</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card className="mb-4 feature-card">
            <Card.Body>
              <div className="d-flex align-items-center mb-3">
                <Icon icon="logos:deployhq-icon" width="3em" height="3em" />
                <h4 className="mb-0">Deploy faster</h4>
              </div>
              <p>Build digital experiences with greater speed and deploy your app in seconds.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} md={6}>
          <Card className="mb-4 feature-card">
            <Card.Body>
              <div className="d-flex align-items-center mb-3">
                <Icon icon="flat-color-icons:multiple-devices" width="3em" height="3em" />
                <h4 className="mb-0">Multiple platform support</h4>
              </div>
              <p>Support Node.js, Python, Java, Go web apps or backend programs.</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card className="mb-4 feature-card">
            <Card.Body>
              <div className="d-flex align-items-center mb-3">
                <Icon icon="clarity:command-solid" width="3em" height="3em" />
                <h4 className="mb-0">Easy use CLI tool</h4>
              </div>
              <p>Deploy web app from local source code by easy use CLI tool.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginTop: "60px" }}>
        <Col className='text-center'>
          <h2>Multiple platform support, easy to get started.</h2>
          <p>Helping you on various platforms to integrate quickly, efficiently collaborate on development and business, and get products to market rapidly.</p>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginBottom: "50px", marginTop: "30px" }}>
        <Col className='text-center'>
          <Icon icon="skill-icons:golang" width="4em" height="4em" />
        </Col>
        <Col className='text-center'>
          <Icon icon="logos:python" width="4rem" height="4rem" />
        </Col>
        <Col className='text-center'>
          <Icon icon="logos:nodejs" width="4em" height="4em" />
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginBottom: "50px", marginTop: "30px" }}>
        <Col className='text-center'>
          <Button variant='primary' size='lg' className="explore-button" href="https://docs.connectdev.io" target='_blank'>Explore platform</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Features;
