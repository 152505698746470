// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.features-container {
  background: linear-gradient(to right, #f0f4f8, #e0e7ef);
  padding: 40px 0;
  border-radius: 10px;
}

.feature-card {
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

h2 {
  color: #333;
  margin-top: 20px;
}

p {
  color: #666;
}

.icon-animation {
  transition: transform 0.3s;
}

.icon-animation:hover {
  transform: scale(1.1);
}

.explore-button {
  transition: background-color 0.3s, transform 0.3s;
}

.explore-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/components/Features/Features.css"],"names":[],"mappings":"AAAA;EACE,uDAAuD;EACvD,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,2CAA2C;EAC3C,yCAAyC;EACzC,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,yCAAyC;AAC3C;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iDAAiD;AACnD;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,yCAAyC;AAC3C","sourcesContent":[".features-container {\n  background: linear-gradient(to right, #f0f4f8, #e0e7ef);\n  padding: 40px 0;\n  border-radius: 10px;\n}\n\n.feature-card {\n  transition: transform 0.3s, box-shadow 0.3s;\n  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);\n  border: 1px solid #e0e0e0;\n  border-radius: 10px;\n}\n\n.feature-card:hover {\n  transform: translateY(-5px);\n  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);\n}\n\nh2 {\n  color: #333;\n  margin-top: 20px;\n}\n\np {\n  color: #666;\n}\n\n.icon-animation {\n  transition: transform 0.3s;\n}\n\n.icon-animation:hover {\n  transform: scale(1.1);\n}\n\n.explore-button {\n  transition: background-color 0.3s, transform 0.3s;\n}\n\n.explore-button:hover {\n  background-color: #0056b3;\n  transform: scale(1.05);\n  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
