import request from '../utils/request';
import {ClientAuthTokens} from '../common/interface';


export const createPersonalToken = (params) => {
  return request.post('api/token', params);
};




export const listTokens = () => {
    return request.get<ClientAuthTokens[]>('api/tokens' , {});
};
