import React, { useState, useEffect, useCallback } from 'react';
import { Container, Tab, Tabs, Form, Card, Row, Col } from 'react-bootstrap';
import { getApplications } from '../../services/application';
import { usage } from '../../services/quota';
import type * as Type from '../../common/interface';
import './style.css';

const Usage: React.FC = () => {
    const [applications, setApplications] = useState<Type.ApplicationInfo[]>([]);
    const [selectedApp, setSelectedApp] = useState<string>('');
    const [usageData, setUsageData] = useState<Type.DailyQuota | null>(null);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState<string>('');

    useEffect(() => {
        fetchApplications();
    }, []);

    const fetchUsageData = useCallback(async () => {
        setLoading(true);
        try {
            const app = applications.find(app => app.appId === selectedApp);
            if (app) {
                const data = await usage(app.appId, app.masterKey);
                setUsageData(data);
            }
        } catch (error) {
            console.error('Failed to fetch usage data:', error);
        } finally {
            setLoading(false);
        }
    }, [selectedApp, applications]);

    useEffect(() => {
        if (selectedApp) {
            fetchUsageData();
        }
    }, [selectedApp, fetchUsageData]);

    const fetchApplications = async () => {
        try {
            const response = await getApplications({});
            setApplications(response.list);
        } catch (error) {
            console.error('Failed to fetch applications:', error);
        }
    };



    const renderUsageData = () => {
        if (!usageData) return null;

        const today = new Date();
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(today.getDate() - 30);
        const maxDate = today.toISOString().split('T')[0];
        const minDate = thirtyDaysAgo.toISOString().split('T')[0];

        const filteredData = Object.entries(usageData)
            .filter(([date]) => {
                if (!selectedDate) return true;
                return date === selectedDate;
            })
            .sort(([dateA], [dateB]) => dateB.localeCompare(dateA));

        return (
            <Card className="mt-4">
                <Card.Body>
                    <div className="alert alert-info mb-3">
                        Note: Usage data is only available for the last 30 days.
                    </div>
                    
                    <Row className="mb-3">
                        <Col xs={12} md={6} lg={3}>
                            <Form.Group>
                                <Form.Label>Filter by Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                    min={minDate}
                                    max={maxDate}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Extra Traffic</th>
                                    <th>Memory Quotas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map(([date, data]) => (
                                    <tr key={date}>
                                        <td>{date}</td>
                                        <td>{data.enabled ? 'Enabled' : 'Disabled'}</td>
                                        <td>{data.extraTraffic}</td>
                                        <td>
                                            {Object.keys(data.quota).length > 0 
                                                ? Object.entries(data.quota)
                                                    .map(([memory, count]) => (
                                                        <div key={memory} className="quota-item">
                                                            <span>
                                                                {
                                                                    <>
                                                                        <span className="memory-spec">{`standard-${memory}`}</span>
                                                                        {`: `}
                                                                        <span className="instance-count">{`${count} instances`}</span>
                                                                    </>
                                                                }
                                                            </span>
                                                        </div>
                                                    ))
                                                : 'No quota'
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Card.Body>
            </Card>
        );
    };

    return (
        <Container className="py-4">
            <h2 className="mb-4">Usage Analytics</h2>
            
            <Card className="p-4 mb-4">
                <Form.Group>
                    <Form.Label>Select Application</Form.Label>
                    <Form.Select 
                        value={selectedApp}
                        onChange={(e) => setSelectedApp(e.target.value)}
                    >
                        <option value="">Choose an application...</option>
                        {applications.map((app) => (
                            <option key={app.appId} value={app.appId}>
                                {app.name}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Card>

            <Tabs defaultActiveKey="instance-usage" className="mb-4">
                <Tab eventKey="instance-usage" title="Instance Usage">
                    <Card className="p-4">
                        {loading ? (
                            <div className="text-center mt-4">Loading...</div>
                        ) : (
                            renderUsageData()
                        )}
                    </Card>
                </Tab>
            </Tabs>
        </Container>
    );
};

export default Usage;
