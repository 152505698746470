import { create } from 'zustand';

import type { UserInfo } from '@/common/interface';
import Storage from '../utils/storage';
import { LOGGED_TOKEN_STORAGE_KEY } from '../common/constants';

interface UserInfoStore {
  user: UserInfo;
  update: (params: UserInfo) => void;
  clear: (removeToken?: boolean) => void;
}

const initUser: UserInfo = {
  clientId: '',
  access_token: '',
};

const loggedUserInfo = create<UserInfoStore>((set) => ({
  user: initUser,
  update: (params) => {
    if (typeof params !== 'object' || !params) {
      return;
    }
    set(() => {
      Storage.set(LOGGED_TOKEN_STORAGE_KEY, params.access_token);
      return { user: params };
    });
  },
  clear: (removeToken = true) =>
    set(() => {
      if (removeToken) {
        Storage.remove(LOGGED_TOKEN_STORAGE_KEY);
      }
      return { user: initUser };
    }),
}));

export default loggedUserInfo;
