import React, {useState, useEffect} from 'react';
import { Modal,Form,Button, Spinner } from 'react-bootstrap';
import { createApplication } from '../../services/application';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPlus, FaTimes } from 'react-icons/fa';

const EMPTY_VALUE = {
    value: '',
    errorMsg: '',
    isInvalid: false
}

interface Props {
    refreshFn: () => void;
}

const Index:React.FC<Props> = ({refreshFn}) => {
    const [show, setShow] = useState(false);
    const [isApplicationCreated, setIsApplicationCreated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const [formData, setFormData] = useState({
        appName: EMPTY_VALUE,
        appDesc: EMPTY_VALUE
      });

    useEffect(() => {
        if (isApplicationCreated) {
            refreshFn()
            setIsApplicationCreated(false);
        }
    }, [isApplicationCreated, refreshFn]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: {
              ...prevData[name],
              value: value,
              isInvalid: false,
              errorMsg: '',
            }
        }));
      };

    const checkValidated = (): boolean => {
        let bol = true;
        const { appName, appDesc } = formData;
        if (!appName.value) {
            bol = false;
            formData.appName = {
                value: '',
                isInvalid: true,
                errorMsg: 'Application Name cannot be empty',
            };
        } else if (appName.value.length > 30) {
            bol = false;
            formData.appName = {
                value: appName.value,
                isInvalid: true,
                errorMsg: 'Application Name cannot be more than 30 characters',
            };
        }

        if (!appDesc.value) {
            bol = false;
            formData.appDesc = {
                value: '',
                isInvalid: true,
                errorMsg: 'Application Description cannot be empty',
            };
        } else if (appDesc.value.length > 300) {
            bol = false;
            formData.appDesc = {
                value: appDesc.value,
                isInvalid: true,
                errorMsg: 'Application Description cannot be more than 300 characters',
            };
        }
        
        setFormData({
            ...formData,
        });
        return bol;
    };

    const handleClose = () => {
        setFormData({
            appName: EMPTY_VALUE,
            appDesc: EMPTY_VALUE
        });
        setShow(false);
    } 

    const handleSubmit = () => {
        if (!checkValidated()) {
            return;
        }

        setIsLoading(true);

        createApplication({
            name: formData.appName.value,
            description: formData.appDesc.value
        }).then((res) => {
            setFormData({
                appName: EMPTY_VALUE,
                appDesc: EMPTY_VALUE
            });
            setShow(false);
            toast.success('Application created successfully', {
                autoClose: 1000,
            }); 
            setIsApplicationCreated(true);
        }).catch((err) => {
            toast.error('Network Error, Please try again later');
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
             <>
                <Button variant="dark" onClick={() => setShow(true)}>
                    <FaPlus /> Add Application
                </Button>
                <ToastContainer />
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Create Application</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form noValidate>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                autoFocus 
                                name="appName" 
                                value={formData.appName.value}  
                                isInvalid={formData.appName.isInvalid}
                                onChange={handleInputChange}
                                style={{ borderColor: formData.appName.isInvalid ? 'red' : 'initial' }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formData.appName.errorMsg}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3} 
                                name='appDesc' 
                                value={formData.appDesc.value} 
                                isInvalid={formData.appDesc.isInvalid}
                                onChange={handleInputChange}
                                style={{ borderColor: formData.appDesc.isInvalid ? 'red' : 'initial' }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formData.appDesc.errorMsg}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            <FaTimes /> Cancel
                        </Button>
                        <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
                            {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Create'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
    )
}

export default React.memo(Index);