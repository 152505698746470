import React, { useState } from 'react';
import { Card, Row, Col, Badge, ListGroup, Button, Form } from 'react-bootstrap';
import type { Group } from '../../common/interface';
import UpgradePlan from '../UpgradePlan';
import QuotaAdjustment from '../QuotaAdjustment';
import './style.css';
import { autoScaleInstances, putProdQuota, scaleInstances, restartInstances } from '@/services';
import { ToastContainer, toast } from 'react-toastify';

interface GroupProps {
  group: Group;
  appId: string;
  masterKey: string;
}

const GroupComponent: React.FC<GroupProps> = ({ 
  group, 
  appId, 
  masterKey,
}) => {
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [showQuotaAdjust, setShowQuotaAdjust] = useState(false);
  const [instances] = useState<Record<string, number>>(
    Object.fromEntries(group.envInfos.map(env => [env.env, env.instances]))
  );

  const handleInstanceClick = () => {
    setShowQuotaAdjust(true);
  };

  const handleUpgradeClick = () => {
    setShowUpgrade(true);
  };

  const handleRestartClick = async (env: string, versionTag: string) => {
    try {
      await restartInstances(appId, masterKey, {
        async: true,
        skipFunctions: true,
        versionTag: versionTag,
      }, env);
      toast.success('Instances restarted successfully');
    } catch (error) {
      toast.error('Failed to restart instances: ' + (error as Error).message);
    }
  };

  return (
    <>
      <ToastContainer />

      <Card className="group-card mb-2">
        <Card.Header className="d-flex justify-content-between align-items-center py-2">
          <h5 className="mb-0">{group.groupName}</h5>
        </Card.Header>
        
        <Card.Body className="py-2">
          <Row className="g-2">
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item className="py-1">
                  <div className="d-flex justify-content-between">
                    <small className="text-muted">Resources:</small>
                    <div className="text-end">
                      CPU: {group.quotaSpec.cpu} cores, 
                      Memory: {group.quotaSpec.memory}MB
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="py-1">
                  <div className="d-flex justify-content-between">
                    <small className="text-muted">Auto Scale:</small>
                    <div>{group.autoScale}</div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="py-1">
                  <div className="d-flex justify-content-between">
                    <small className="text-muted">Created:</small>
                    <div>{new Date(group.created).toLocaleDateString()}</div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>

          <div className="mt-2">
            <h6 className="mb-1">Environments</h6>
            <Row className="g-2">
              {group.envInfos.map((env) => (
                <Col key={env.env} md={6}>
                  <Card className="env-card">
                    <Card.Body className="py-2 px-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h6 className="mb-0">
                          {env.env === '1' ? 'Free Plan' : 'Standard Plan'}
                        </h6>
                        <Badge bg={env.deployable ? 'success' : 'secondary'}>
                          {env.deployable ? 'Deployable' : 'Not Deployable'}
                        </Badge>
                      </div>
                      
                      {env.deployable && (
                        <div className="mt-2">
                          <div className="d-flex align-items-center gap-2">
                            <Form.Label className="mb-0 small">Instances:</Form.Label>
                            <Form.Control
                              type="number"
                              size="sm"
                              style={{ width: '80px' }}
                              value={instances[env.env]}
                              readOnly
                            />
                            <Button 
                              size="sm" 
                              variant="outline-secondary"
                              onClick={() => handleInstanceClick()}
                            >
                              Adjust
                            </Button>
                            <Button 
                              size="sm" 
                              variant="outline-primary"
                              onClick={() => handleUpgradeClick()}
                            >
                              Upgrade
                            </Button>
                            <Button 
                              size="sm" 
                              variant="outline-warning"
                              onClick={() => handleRestartClick(env.env, group.production.version?.versionTag ?? '')}
                            >
                              Restart
                            </Button>
                          </div>
                        </div>
                      )}
                      
                      {!env.deployable && (
                        <small className="text-muted d-block mt-1">
                          Instances: {env.instances}
                        </small>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </Card.Body>
      </Card>

      <QuotaAdjustment
        onHide={() => setShowQuotaAdjust(false)}
        onConfirm={async (config: any) => {
          try {    
            if (config.mode === 'fixed') {
                scaleInstances(appId, masterKey, config.instanceCount);
            } else {
               autoScaleInstances(appId, masterKey, config.strategy, config.scaleMax, config.scaleMin);
            }
            toast.success('Scale updated successfully', {
              autoClose: 1000,
            });
            setShowQuotaAdjust(false);
          } catch (error) {
            console.error('Failed to update instances:', error);
            toast.error('Failed to update instances');
          }
        }}
        group={group}
        show={showQuotaAdjust}
      />

      <UpgradePlan
        group={group}
        show={showUpgrade}
        onHide={() => setShowUpgrade(false)}
        onConfirm={async (quotaId) => {
          try {
            await putProdQuota(appId, masterKey, quotaId);
            toast.success('Quota updated successfully', {
                autoClose: 1000,
            }); 
            setShowUpgrade(false);
          } catch (error: any) {
            toast.error(error.error);
          }
        }}
        appId={appId}
        masterKey={masterKey}
      />
    </>
  );
};

export default GroupComponent;
