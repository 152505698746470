

import type * as Type from '../common/interface';
import { CLIENT_API_URL } from '../common/constants';
import axios from 'axios';


export async function getPricing (params) : Promise<Type.ListResult> {
    const response = await axios.get(`${CLIENT_API_URL}/api/pricing`, { params });
    return response.data;
};


