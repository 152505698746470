import { createBrowserRouter } from "react-router-dom";
import AuthCallback from "../pages/AuthCallback";
import ErrorPage from "../pages/ErrorPage";
import Layout from "../pages/Layout";
import Error404 from "../pages/Error404";
import { setupApp } from "../utils/guard";
import ApplicationList from "../pages/ApplicationList";
import ApplicationDetail from "../pages/ApplicationDetail";
import Login from "../pages/Login";
import Account from "../pages/Account";
import Pricing from "../pages/Pricing";
import Usage from "../pages/Usage";
import { requireAuth } from "../utils/guard";
import { initGA, logPageView } from "../utils/analytics";

const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <ErrorPage />,
      loader: async () => {
        await setupApp();
        initGA('G-TX8TRF4LGL');
        logPageView();
        return null;
      },
      children: [
        {
            path: 'users/auth-landing',
            element: <AuthCallback />,
        },
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'pricing',
          element: <Pricing />,
        },
        {
          path: 'console/applications',
          element: <ApplicationList />,
          loader: requireAuth,
        },
        {
          path: 'console/application/:applicationId',
          element: <ApplicationDetail />,
          loader: requireAuth,
        },
        {
          path: 'account',
          element: <Account />,
          loader: requireAuth,
        },
        {
          path: 'usage',
          element: <Usage />,
          loader: requireAuth,
        },
        {
            path: '*',
            element: <Error404 />,
        }
      ],
    }
    
  ]);


export default router;