import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getQuotaConfig } from '../../services/quota';
import styles from './index.module.css';
import type { Group, QuotaConfig } from '../../common/interface';

interface UpgradePlanProps {
  show: boolean;
  onHide: () => void;
  onConfirm: (quotaId: string) => void;
  appId: string;
  masterKey: string;
  group: Group;
}

const UpgradePlan: React.FC<UpgradePlanProps> = ({
  show,
  onHide,
  onConfirm,
  appId,
  masterKey,
  group,
}) => {
  const [quotaConfig, setQuotaConfig] = React.useState<QuotaConfig | null>(null);
  const [selectedQuota, setSelectedQuota] = React.useState<string>(group.instanceQuota);

  React.useEffect(() => {
    if (show) {
      getQuotaConfig(appId, masterKey).then(data => {
        setQuotaConfig(data);
      });
    }
  }, [show, appId, masterKey]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Choose Instance Specification</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        <div className={styles.planContainer}>
          {/* Free Plan */}
          <div className={styles.section}>
            <h3>Free Plan</h3>
            <div className={`${styles.planCard} ${styles.disabled}`}>
              <div>Free</div>
              <div className={styles.specs}>
                <span>{group?.quotaSpec.memory} MB</span>
                <span>{group?.quotaSpec.cpu} CPU</span>
              </div>
              <div>Free</div>
            </div>
            <div className={styles.description}>
              Free plan has sleep mode enabled and is for testing purposes only.
            </div>
          </div>

          {/* Standard Plans */}
          <div className={styles.section}>
            <h3>Standard Plans</h3>
            <div className={styles.planGrid}>
              {quotaConfig?.prodQuotas.map((quota) => (
                <div
                  key={quota.id}
                  className={`${styles.planCard} ${
                    selectedQuota === quota.id ? styles.selected : ''
                  }`}
                  onClick={() => setSelectedQuota(quota.id)}
                >
                  <div>standard-{quota.memory}</div>
                  <div className={styles.specs}>
                    <span>{quota.memory} MB</span>
                    <span>{quota.cpu} CPU</span>
                  </div>
                  <div>{quota.baseQuotas} CNY / Day</div>
                </div>
              ))}
            </div>
            <div className={styles.description}>
              Standard plans do not sleep and support scaling and pre-production environments.
            </div>
          </div>

          <div className={styles.notice}>
            <i className={`${styles.warningIcon} fas fa-exclamation-triangle`}></i>
            <span>
              <strong>Warning:</strong> All instances will restart after changing specifications. 
              Although service will not be interrupted during restart, please be cautious when upgrading in production environment.
            </span>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={() => onConfirm(selectedQuota)}
          disabled={!selectedQuota}
        >
          Confirm and Upgrade
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpgradePlan; 