
import { CLIENT_API_URL, GITHUB_CLIENT_ID } from '../common/constants';

const CALLBACK_URL = process.env.GITHUB_CALLBACK_URL || `${CLIENT_API_URL}/api/callback`
const CLIENT_ID = process.env.GITHUB_CLIENT_ID || GITHUB_CLIENT_ID

export function getGithuhLoginUrl(): string {
  const baseUrl = 'https://github.com/login/oauth/authorize';
  const scope = 'user:email';
  const urlParams = new URLSearchParams({
    client_id: CLIENT_ID,
    scope: scope,
    redirect_uri: CALLBACK_URL,
  });
  return `${baseUrl}?${urlParams.toString()}`;
}
