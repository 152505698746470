import { Link } from "react-router-dom";
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import { navigateToLogin } from '../../utils/guard';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  if (isRouteErrorResponse(error) && error.status === 401) {
    navigateToLogin();
    return null;
  }

  return (
    <>
      <div id="error-page" className="text-center">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <Link to="/" className="btn btn-link">
          Back to Home
        </Link>
      </div>
    </>
   
    
  );
}