import { FC, memo, ReactNode } from 'react';

const Index: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div className="text-center py-5">
      {children || 'No data available'}
    </div>
  );
};

export default memo(Index);
