
import config from '../config';
import axios from 'axios';


const serverRequest = axios.create({
    baseURL: config.baseUrl,
    timeout: 10000,
  });

const scrollToDocTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant' as ScrollBehavior,
      });
    });
};





  /**
 * Determine if a Link click event should be handled
 */
const shouldProcessLinkClick = (evt) => {
    if (evt.defaultPrevented) {
      return false;
    }
    const nodeName = evt.currentTarget?.nodeName;
    if (nodeName?.toLowerCase() !== 'a') {
      return false;
    }
    const target = evt.currentTarget?.target;
    return (
      evt.button === 0 &&
      (!target || target === '_self') &&
      !(evt.metaKey || evt.ctrlKey || evt.shiftKey || evt.altKey)
    );
  };
  

export { 
    scrollToDocTop,
    serverRequest,
    shouldProcessLinkClick
};


