import { FC, memo, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { handleLoginWithToken } from '../../utils/guard';

const Index: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const token = searchParams.get('access_token');
    handleLoginWithToken(token, navigate);
  }, [searchParams, navigate]);

  return <div></div>;
};

export default memo(Index);
