import { Container, Row, Col } from 'react-bootstrap';
import { FaDiscord } from 'react-icons/fa'; // 引入图标库

const Footer = () => {
  return (
    <footer className="footer" style={{ backgroundColor: "#f8f9fa", padding: "20px 0", marginBottom: "20px" }}>
      <Container>
        <hr className="footer-divider" />
        <Row>
          <Col md={6} className="text-left">
            <h5 style={{ color: "#343a40" }}>ConnectDev</h5>
          </Col>
          <Col md={6} className="text-right">
            <span style={{ color: "#343a40" }}>Contact us via </span>
            <a href='mailto:support@connectdev.io' style={{ color: "#007bff", textDecoration: "none" }}>Email</a> | 
            <a href='https://discord.gg/94acDV9Z' style={{ color: "#007bff", textDecoration: "none", marginLeft: "5px" }}>
              <FaDiscord style={{ marginRight: "5px" }} /> Discord
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
