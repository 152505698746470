import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './style.css';
import { Group } from '@/common/interface';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

interface QuotaAdjustmentProps {
  show: boolean;
  onHide: () => void;
  onConfirm: (config: any) => void;
  group: Group;
}

const QuotaAdjustment: React.FC<QuotaAdjustmentProps> = ({ show, onHide, onConfirm, group }) => {
  const [instanceCount, setInstanceCount] = useState(group.instances.length);
  const [mode, setMode] = useState<'fixed' | 'auto'>('fixed');
  const [strategy, setStrategy] = useState<'balanced' | 'lower-cost' | 'more-stable'>('balanced');
  const [scaleMin, setScaleMin] = useState(1);
  const [scaleMax, setScaleMax] = useState(2);

  const handleClose = () => {
    setMode('fixed');
    setStrategy('balanced');
    setScaleMin(1);
    setScaleMax(2);
    onHide();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Adjust Instance Quota</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>

        <div className="instance-count-section">
          <div className="mode-selector">
            <button 
              className={mode === 'fixed' ? 'active' : ''} 
              onClick={() => setMode('fixed')}
            >
              Fixed Count
            </button>
            <button 
              className={mode === 'auto' ? 'active' : ''} 
              onClick={() => setMode('auto')}
            >
              Auto Scaling
            </button>
          </div>

          {mode === 'fixed' ? (
            <div className="count-slider">
              <input 
                type="range" 
                min="1" 
                max="20" 
                value={instanceCount}
                onChange={(e) => setInstanceCount(Number(e.target.value))}
              />
              <div className="count-display">{instanceCount}</div>
            </div>
          ) : (
            <div>
              <RangeSlider
                min={1}
                max={10}
                value={[scaleMin, scaleMax]}
                onInput={([min, max]) => {
                  setScaleMin(min);
                  setScaleMax(max);
                }}
              />
              <div className="scale-display">
                <span className="min-value">Min: <span className="number">{scaleMin}</span></span>
                <span className="max-value">Max: <span className="number">{scaleMax}</span></span>
              </div>
            </div>
          )}
        </div>

        {mode === 'auto' && (
          <div className="strategy-section">
            <h3>Scaling Strategy</h3>
            <div className="strategy-selector">
              <button 
                className={strategy === 'lower-cost' ? 'active' : ''} 
                onClick={() => setStrategy('lower-cost')}
              >
                Cost Priority
              </button>
              <button 
                className={strategy === 'balanced' ? 'active' : ''} 
                onClick={() => setStrategy('balanced')}
              >
                Balanced
              </button>
              <button 
                className={strategy === 'more-stable' ? 'active' : ''} 
                onClick={() => setStrategy('more-stable')}
              >
                Performance Priority
              </button>
            </div>
          </div>
        )}

      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={() => onConfirm({
            mode,
            instanceCount,
            strategy,
            scaleMax,
            scaleMin,
          })}
          disabled={!instanceCount}
        >
          Confirm Adjustment
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuotaAdjustment;