import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import './styles.css';

interface Step {
  number: number;
  title: string;
  content: React.ReactNode;
  commands?: string[];
}

interface GuidedTourProps {
  steps: Step[];
}

const GuidedTour: React.FC<GuidedTourProps> = ({ steps }) => {
  const [activeKeys, setActiveKeys] = useState<string[]>(["0"]);

  const handleSelect = (eventKey: string) => {
    if (activeKeys.includes(eventKey)) {
      setActiveKeys(activeKeys.filter(key => key !== eventKey));
    } else {
      setActiveKeys([...activeKeys, eventKey]);
    }
  };

  return (
    <div className="guided-tour">
      <Accordion>
        {steps.map((step, index) => (
          <Accordion.Item key={index} eventKey={index.toString()}>
            <Accordion.Header 
              onClick={() => handleSelect(index.toString())}
              className={activeKeys.includes(index.toString()) ? 'active' : ''}
            >
              <span className="step-number">{step.number}</span>
              {step.title}
            </Accordion.Header>
            <Accordion.Body>
              {step.content}
              {step.commands && (
                <div className="command-block">
                  {step.commands.map((command, cmdIndex) => (
                    <div className="command-line" key={cmdIndex}>
                      <span className="prompt">$</span>
                      <code>{command}</code>
                      <button className="copy-button">
                        <Icon icon="icon-park-twotone:copy" width="20" height="20" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default GuidedTour; 