import React, { useEffect, useState } from 'react';
import { getLogs } from '../../services';
import type * as Type from '../../common/interface';
import './index.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const DLogs = ({ appId, masterKey, activeTab }) => {
    const [logs, setLogs] = useState<Type.Log[]>([]);
    const [autoUpdate, setAutoUpdate] = useState(false);
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [selectedStream, setSelectedStream] = useState<string>('all');

    const formatTime = (timeStr: string) => {
        const date = new Date(timeStr);
        return new Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).format(date);
    };

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const from = fromDate ? fromDate.toISOString() : undefined;
                const stream = selectedStream === 'all' ? undefined : selectedStream;
                const logs = await getLogs(appId, masterKey, from, stream);
                setLogs(logs);
            } catch (error) {
                console.error('Failed to fetch logs:', error);
            }
        };

        if (activeTab === 'logs') {
            fetchLogs();
            
            let interval: NodeJS.Timeout;
            if (autoUpdate) {
                interval = setInterval(fetchLogs, 5000);
            }

            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            };
        }
    }, [appId, masterKey, activeTab, autoUpdate, fromDate, selectedStream]);

    return (
        <div className="logs-container">
            <div className="controls">
                <div className="auto-update-control">
                    <label>
                        <input
                            type="checkbox"
                            checked={autoUpdate}
                            onChange={(e) => setAutoUpdate(e.target.checked)}
                        />
                        Auto Update (5s)
                    </label>
                </div>
                <div className="date-filter">
                    <label>
                        From Date:
                        <DatePicker
                            selected={fromDate}
                            onChange={(date: Date | null) => setFromDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            isClearable={true} 
                            timeCaption="time"
                            dateFormat="yyyy-MM-dd HH:mm"
                            placeholderText="Select date and time"
                            className="form-control"
                        />
                    </label>
                </div>
                <div className="stream-filter">
                    <label>
                        Stream:
                        <select 
                            value={selectedStream}
                            onChange={(e) => setSelectedStream(e.target.value)}
                            className="form-control"
                        >
                            <option value="all">All</option>
                            <option value="stdout">Output</option>
                            <option value="stderr">Error</option>
                        </select>
                    </label>
                </div>
            </div>
            {logs.map(log => (
                <div key={log.id} className={`log-entry ${log.type} ${log.stream}`}>
                    <div className="log-header">
                        <span className="log-time">{formatTime(log.time)}</span>
                        <span className="log-type">{log.type}</span>
                        <span className="log-group">{log.groupName}</span>
                        <span className={`log-env ${log.prod ? 'prod' : 'dev'}`}>
                            {log.prod ? 'PROD' : 'DEV'}
                        </span>
                    </div>
                    <div className="log-content">{log.content}</div>
                </div>
            ))}
        </div>
    );
};

export default DLogs;