import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import { getPricing } from '../../services/pricing';
import type { PriceItem } from '../../common/interface';
import './index.css';

const Pricing: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [priceList, setPriceList] = useState<PriceItem[]>([]);

  useEffect(() => {
    const fetchPricing = async () => {
      setLoading(true);
      try {
        const response = await getPricing({"region": "us"});
        setPriceList(response.list);
      } catch (error) {
        console.error('Failed to fetch pricing:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPricing();
  }, []);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="mt-4">
      <div className="text-center mb-5">
        <h1 className="display-4 mb-3">Pricing Plans</h1>
        <p className="lead text-muted pricing-intro">
          Whether you're seeking a place for your personal project or gearing up to launch 
          the next big idea, we have a plan that fits your needs.
        </p>
        <div className="divider my-4"></div>
      </div>

      <Row xs={1} md={2} className="g-4 justify-content-center">
        {priceList.map((item) => (
          <Col key={`${item.service}-${item.item}`} className="pricing-col">
            <Card className="h-100 shadow-sm hover-effect">
              <Card.Header className="bg-primary text-white py-3">
                <h4 className="mb-0">{item.service}</h4>
              </Card.Header>
              <Card.Body className="d-flex flex-column">
                <Card.Title className="text-center mb-4">{item.item}</Card.Title>
                <Card.Text className="pricing-amount">
                  <span className="display-4">${item.price.toFixed(2)}</span>
                  <small className="text-muted">/{item.period}</small>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Pricing;


