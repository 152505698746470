import React,{useEffect, useState, useCallback} from 'react';
import { Card, Container, Row,Col, Modal, Form, Button } from 'react-bootstrap';
import {getApplications, deleteApplication } from '../../services/application';
import {useSearchParams} from 'react-router-dom';
import type * as Type from '../../common/interface';
import { loggedUserInfoStore } from '../../stores';
import { useNavigate } from 'react-router-dom';
import GuidedTour from '../../components/GuidedTour';

import {
    Pagination,
} from '../../components';
import './index.css'
import Application from '../Application';

const PAGE_SIZE = 20;

const Index:React.FC = () => {
    const [urlSearchParams] = useSearchParams();
    const curPage = Number(urlSearchParams.get('page')) || 1;
   
    const [listData, setListData] = useState<Type.ListResult | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteAppName, setDeleteAppName] = useState('');
    const [selectedApp, setSelectedApp] = useState<Type.ApplicationInfo | null>(null);
    const [loading, setLoading] = useState(false);

    const onRefresh = useCallback(() => {
        setLoading(true);
        getApplications({
            page_size: PAGE_SIZE,
            page: curPage,
        }).then((res) => {
            setListData(res);
        }).finally(() => {
            setLoading(false);
        });
    }, [curPage]);

    useEffect(() => {
        onRefresh()
    }, [onRefresh]);

    const count = listData?.count || 0


    const { user } = loggedUserInfoStore();
    const navigate = useNavigate();

     if (!user.clientId) {
        navigate('/');
        return null; 
    }

    const handleDelete = (app: Type.ApplicationInfo) => {
        setSelectedApp(app);
        setShowDeleteModal(true);
        setDeleteAppName('');
    };

    const setupSteps = [
      {
        number: 1,
        title: "Create a new application",
        content: (
          <>
            <p>Create a new application:</p>
          </>
        )
      },
      {
        number: 2,
        title: "Go to the application detail page",
        content: (
          <>
            <p>Click the application name to go to the application detail page.</p>
          </>
        )
      },
      {
        number: 3,
        title: "Set up the application",
        content: (
          <>
            <p>Set up the application by following the guide on the application detail page.</p>
          </>
        )
      }
    ];

    const confirmDelete = async () => {
        if (selectedApp && deleteAppName === selectedApp.name) {
            try {
                await deleteApplication(selectedApp.appId);
                setShowDeleteModal(false);
                onRefresh();
            } catch (error) {
                console.error('Failed to delete application:', error);
            }
        }
    };

    return (
        console.log(process.env.API_BASE_URL),
        <Container style={{ marginTop: "3rem" }}>
            {loading && <div className="loading-indicator">Loading...</div>}
            <hr className="top-divider" />
            <Row>
                <Col xs={7}>
                    <h2>Applications</h2>
                </Col>
                <Col>
                    <Application refreshFn={onRefresh} />
                </Col>
            </Row>

            <div className='application-list'>
                {listData?.list?.length ? (
                    <Row xs={1} md={3} className="g-4">
                        {listData.list.map((item) => (
                            <Col key={item.appId}>
                                <Card className="application-card h-100 shadow-sm">
                                    <Card.Body className="d-flex flex-column">
                                        <Card.Title>
                                            <a 
                                                href={`/console/application/${item.appId}`}
                                                className="text-decoration-none text-primary"
                                            >
                                                {item.name}
                                            </a>
                                        </Card.Title>
                                        <Card.Text className="text-muted flex-grow-1">
                                            {item.description || 'No description provided'}
                                        </Card.Text>
                                        <div className="mt-auto pt-3 d-flex justify-content-between align-items-center">
                                            <small className="text-muted">
                                                App ID: {item.appId}
                                            </small>
                                            <Button 
                                                variant="outline-danger" 
                                                size="sm"
                                                onClick={() => handleDelete(item)}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <div className="text-center mt-4">
                        <p>No applications found. Please create a new application.</p>
                        <div className="setup-guide">
                        <h4>Getting Started</h4>
                        <GuidedTour steps={setupSteps} />
                      </div>
                    </div>
                )}
                <div className="mt-4 mb-2 d-flex justify-content-center">
                    <Pagination
                        currentPage={curPage}
                        totalSize={count}
                        pageSize={PAGE_SIZE}
                    />
                </div>
            </div>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Application</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>To confirm deletion, please type the application name: <strong>{selectedApp?.name}</strong></p>
                    <Form.Control
                        type="text"
                        value={deleteAppName}
                        onChange={(e) => setDeleteAppName(e.target.value)}
                        placeholder="Enter application name"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={confirmDelete}
                        disabled={deleteAppName !== selectedApp?.name}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default React.memo(Index);