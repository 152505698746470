import { FC, memo } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { FaRocket } from 'react-icons/fa';


const Brief: FC = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <h1 className="text-center">ConnectDev</h1>
          <p className="text-center fs-5 mb-3"> Unleash the power of seamless backend hosting for your Applications. Deploy your app in seconds. No credit card required.</p>
          <div className="d-flex justify-content-center">
                <Button variant="primary" style={{ marginRight: '10px', transition: '0.3s' }} href="https://docs.connectdev.io/docs/getting-started" target='_blank'>
                  <FaRocket /> Getting started
                </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default memo(Brief);