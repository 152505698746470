import React, { FC, memo } from 'react'
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { loggedUserInfoStore } from '../../stores';
import NavItems from './NavItems';
import './Header.css';
import { handleLogout } from '../../utils/guard';

const Header: FC = () => {
    const { user } = loggedUserInfoStore();
    const onLogout = () => {
        handleLogout();
    };
    return (
        <Navbar className="custom-navbar" expand="lg"> {}
            <Container>
                <Navbar.Brand href="/" className="brand">ConnectDev</Navbar.Brand> {}
                <Nav className="me-auto">
                    <Nav.Link href="https://docs.connectdev.io" target='_blank' className="nav-link">Docs</Nav.Link> {}
                    <Nav.Link href="/pricing" className="nav-link">Pricing</Nav.Link> {}
                </Nav>
                {user?.clientId ? (
                    <NavItems userInfo={user} logOut={onLogout} />
                ) : (
                    <>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav >
                            <Button variant="outline-info" className="get-started-btn" href='/login'>Get started for free</Button> {}
                            <Button className="login-btn" href="/login">Log in</Button> {}
                            <Button 
                            variant='light'
                            className="signup-btn"
                            href="/login">Sign up</Button> {}
                        </Nav>
                        </Navbar.Collapse>
                    </>
                )}
                <hr className="header-divider" />
            </Container>
        </Navbar>
    )
}

export default memo(Header);
