import { FC, memo } from 'react';
import { SWRConfig } from 'swr';
import { Outlet,useLocation } from 'react-router-dom';

import {
  Header,
  Footer,
  Brief,
  Features,
} from '../../components';

const Layout: FC = () => {
  const location = useLocation();

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
     <SWRConfig value={{ revalidateOnFocus: false }}>
        <Header />
        {location.pathname === "/" && <div style={{marginTop: "100px"}}>
          <Brief/>
        </div> }
        {location.pathname === "/" &&
        <div style={{marginTop:"80px"}}>
          <Features/>
        </div>
        }
        <div style={{ flex: 1 }}>
          <Outlet />
        </div>
        <div style={{  bottom: "20px", width: "100%" }}>
          <Footer />
        </div>
      </SWRConfig>
    </div>
  );
};

export default memo(Layout);